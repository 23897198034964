<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['purchaseOrderTable'].refresh()"
    >
      <template #buttons>
        <v-select
          v-model="selectYear"
          :options="yearOptions"
          :reduce="(obj) => obj.id"
          style="width: 150px"
          label="value"
          class="ml-1"
          @input="changeYearFilter"
        >
          <template #selected-option="{ value }">
            <feather-icon icon="CalendarIcon" class="text-warning" />
            <span style="margin-left: 0.8rem">{{ value }}</span>
          </template>
          <template #option="{ value }">
            <feather-icon icon="CalendarIcon" class="text-success" />
            <span style="margin-left: 0.8rem">{{ value }}</span>
          </template>
        </v-select>
        <b-badge variant="light-success" class="ml-2">
          <span style="font-size: 17px">
            Total: $ {{ totalAmount | currency }}
          </span>
        </b-badge>
      </template>
      <template #table>
        <b-table
          :per-page="paginate.perPage"
          ref="purchaseOrderTable"
          :current-page="paginate.currentPage"
          no-border-collapse
          show-empty
          sticky-header="50vh"
          class="position-relative"
          table-class="text-nowrap"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="myProvider"
          :fields="showFields"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(code)="data">
            <span> # {{ data.value.toString().padStart(8, "0") }} </span>
          </template>
          <template #cell(date)="data">
            <span> {{ data.value | myGlobal }} </span>
          </template>
          <template #cell(status)="data">
            <b-badge
              :variant="`light-${
                data.value == 'Pending' ? 'secondary' : 'primary'
              }`"
            >
              <span> {{ data.value }} </span>
            </b-badge>
          </template>
          <template #cell(total)="data">
            <span class="text-success">
              {{ data.item.currency }} {{ data.value | currency }}
            </span>
          </template>
          <template #cell(items)="data">
            <span class="text-success"> {{ data.value }} </span>
          </template>
          <template #cell(bill_photo)="data">
            <a :href="data.item.bill_photo" target="_blanck">
              <tabler-icon
                v-if="data.item.bill_photo != null"
                icon="FileTextIcon"
                size="22"
                class="cursor-pointer text-danger"
              />
            </a>
          </template>
          <template #cell(rating)="data">
            <div
              v-for="(rate, index) in JSON.parse(data.item.rating)"
              :key="index"
              class="d-flex"
            >
              <div v-if="rate.name == null" class="text-center text-danger">
                No rating added
              </div>
              <template v-else>
                <div class="d-flex align-items-center">
                  <p class="p-0 m-0">{{ rate.name }}:</p>
                </div>
                <div>
                  <b-form-rating
                    v-model="rate.score"
                    variant="warning"
                    size="sm"
                    readonly
                    no-border
                    class="p-0 m-0"
                  >
                  </b-form-rating>
                </div>
              </template>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span> {{ data.item.first_name }} {{ data.item.last_name }} </span>
            <br />
            <span> {{ data.value | myGlobalDay }} </span>
          </template>
          <template #cell(action)="data">
            <feather-icon
              v-if="data.item.status == 'Approved'"
              icon="EyeIcon"
              class="text-primary cursor-pointer"
              v-b-tooltip.hover.left="'View Purchase Order'"
              size="17"
              @click="viewPurchaseOrder(data.item.id)"
            />
            <feather-icon
              v-if="data.item.status == 'Pending'"
              icon="EditIcon"
              v-b-tooltip.hover.left="'Edit Purchase Order'"
              class="text-warning cursor-pointer ml-1"
              size="17"
              @click="editPurchaseOrder(data.item.id)"
            />
            <feather-icon
              v-if="data.item.status == 'Pending'"
              icon="CheckIcon"
              v-b-tooltip.hover.left="'Approve Purchase Order'"
              class="text-primary cursor-pointer ml-1"
              size="17"
              @click="approvePurchaseOrder(data.item.id)"
            />
          </template>

          <template #custom-foot>
            <b-tr>
              <b-td class="text-right" colspan="6">Subtotal: </b-td>
              <b-td colspan="6">
                <span class="text-primary"
                  >$ {{ subtotal | currency }}
                </span></b-td
              >
            </b-tr>
            <b-tr>
              <b-td class="text-right" colspan="6">total: </b-td>
              <b-td colspan="6">
                <span class="text-primary"
                  >$ {{ total | currency }}
                </span></b-td
              >
            </b-tr>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <PurchaseOrderModal
      v-if="purchaseOrderModal"
      :id="purchaseOrderId"
      :view="viewPurchase"
      @hidden="
        purchaseOrderModal = false;
        viewPurchase = false;
      "
      @refresh="
        $refs['purchaseOrderTable'].refresh();
        purchaseOrderModal = false;
        viewPurchase = false;
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import Filters from "@/views/logistic/views/bills/data/filters.data.js";
import Fields from "@/views/logistic/views/bills/data/fields.data.js";

import PurchaseOrderModal from "@/views/logistic/views/bills/components/purchase-order/PurchaseOrderModal.vue";
import BillsService from "@/views/logistic/views/bills/service/bills.service.js";

export default {
  components: {
    PurchaseOrderModal,
  },
  data() {
    return {
      filters: Filters,
      fields: Fields,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      purchaseOrderModal: false,
      purchaseOrderId: null,
      viewPurchase: false,
      selectYear: "",
      totalAmount: 0,
      yearOptions: [],
      subtotal: 0,
      total: 0,
    };
  },
  computed: {
    showFields() {
      return this.fields.filter((row) => row.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.generateYears();
    this.selectYear = moment().year();
    try {
      this.addPreloader();
      await Promise.all([
        this.getCountries(),
        this.getPurchaseTypes(),
        this.getVendors(),
        this.getAmountBillsByYear(),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      this.removePreloader();
    }
  },
  methods: {
    async myProvider(ctx) {
      try {
        this.addPreloader();
        const sortBy = this.getColumn(ctx.sortBy);
        const params = {
          order_by: sortBy,
          orden: !ctx.sortDesc ? "desc" : "asc",
          page: ctx.currentPage,
          perpage: ctx.perPage,
          text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          type: this.filters[2].model,
          country: this.filters[3].model,
          vendor: this.filters[4].model,
          status: this.filters[5].model,
        };
        const { data } = await BillsService.getPruchaseOrders(params);
        this.totalRows = data.total;
        this.startPage = data.from ? data.from : 0;
        this.toPage = data.to ? data.to : 0;
        if (data.data.length > 0) {
          this.subtotal = data.data[0].subtotal;
          this.total = data.data[0].total_bills;
        } else {
          this.subtotal = 0;
          this.total = 0;
        }
        return data.data || [];
      } catch (error) {
        console.log(error);
        this.showErrorSwal();
        return [];
      } finally {
        this.removePreloader();
      }
    },

    async changeYearFilter() {
      try {
        this.addPreloader();
        await this.getAmountBillsByYear();
      } catch (error) {
        console.log(error);
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async getAmountBillsByYear() {
      try {
        const { data } = await BillsService.getTotalAmountByYear({
          year: this.selectYear,
        });
        this.totalAmount = data[0].total;
      } catch (error) {
        console.log(error);
      }
    },

    generateYears() {
      for (let index = 2020; index <= moment().year(); index++) {
        this.yearOptions.push({ id: index, value: "" + index + "" });
      }
    },

    getColumn(val) {
      let column = "18";
      switch (val) {
        case "code":
          column = 9;
          break;
        case "purchase_type":
          column = 13;
          break;
        case "bill_number":
          column = 4;
          break;
        case "vendor_name":
          column = 12;
          break;
        case "date":
          column = 6;
          break;
        case "total":
          column = 7;
          break;
        case "created_at":
          column = 10;
          break;
        default:
          break;
      }

      return column;
    },

    async getVendors() {
      try {
        const { data } = await BillsService.getVendors();
        this.filters[4].options = data;
      } catch (error) {
        this.showErrorSwal();
      }
    },

    async getCountries() {
      try {
        const { data } = await BillsService.getCountries();
        this.filters[3].options = data;
      } catch (error) {
        this.showErrorSwal();
      }
    },

    async getPurchaseTypes() {
      try {
        const { data } = await BillsService.getPurchaseTypes();
        this.filters[2].options = data;
      } catch (error) {
        this.showErrorSwal();
      }
    },

    newPuchaseOrder() {
      this.purchaseOrderId = null;
      this.purchaseOrderModal = true;
    },

    editPurchaseOrder(id) {
      this.purchaseOrderId = id;
      this.purchaseOrderModal = true;
    },

    viewPurchaseOrder(id) {
      this.purchaseOrderId = id;
      this.viewPurchase = true;
      this.purchaseOrderModal = true;
    },

    async approvePurchaseOrder(id) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          const params = {
            id,
            user_id: this.currentUser.user_id,
          };
          const { data } = await BillsService.approvePurchaseOrder(params);
          if (data.type == "warning") {
            this.showWarningSwal("Very Important!", data.message);
          } else if (data.type == "success") {
            this.showSuccessSwal("Success!", data.message);
          }
          await this.getAmountBillsByYear();
          this.$refs["purchaseOrderTable"].refresh();
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>