export default [
    {
        key: "code",
        label: "Code",
        sortable: true,
        visible: true,
    },
    {
        key: "purchase_type",
        label: "Type",
        sortable: true,
        visible: true,
    },
    {
        key: "bill_number",
        sortable: true,
        label: "Bill Number",
        visible: true,
    },
    {
        key: "country",
        label: "Country",
        visible: true,
    },
    {
        key: "vendor_name",
        sortable: true,
        label: "Vendor",
        class: 'text-center',
        visible: true,
    },
    {
        key: "date",
        label: "Purchase date",
        sortable: true,
        tdClass: 'text-center',
        thClass: 'text-center',
        visible: true,
    },
    {
        key: "status",
        label: "Status",
        tdClass: 'text-center',
        thClass: 'text-center',
        visible: true,
    },
    {
        key: "total",
        sortable: true,
        label: "Total Amount",
        tdClass: 'text-center',
        thClass: 'text-center',
        visible: true,
    },
    {
        key: "items",
        label: "Items",
        tdClass: 'text-center',
        thClass: 'text-center',
        visible: true,
    },
    {
        key: "bill_photo",
        label: "File",
        tdClass: 'text-center',
        thClass: 'text-center',
        visible: true,
    },
    {
        key: "rating",
        label: "Rate",
        visible: true,
    },
    {
        key: "created_at",
        label: "Created by",
        sortable: true,
        visible: true,
    },
    {
        key: "action",
        label: "Action",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    }
]