<template>
  <div>
    <b-modal
      ref="modal-purchase-order"
      :title="title + ' PURCHASE ORDER'"
      size="xmd"
      no-close-on-backdrop
      no-close-on-esc
      @hidden="$emit('hidden')"
    >
      <div>
        <ValidationObserver ref="formPurchaseOrder">
          <b-row>
            <b-col lg="4" xl="4">
              <b-row class="py-1">
                <!-- Bill photo -->
                <b-col md="10" xl="10" class="m-auto">
                  <label class="text-primary">*Attach Voucher (Preview is just for images)</label>
                  <b-form-group label="" label-for="bill_number">
                    <div
                      class="form-group ml-3 mr-3"
                      :class="requiredImg ? 'border-danger' : 'border-black'"
                      style="border-radius: 10px; padding: 10px"
                    >
                      <div class="image-upload">
                        <input
                          type="file"
                          id="file_input_1"
                          @change="getImage"
                          hidden
                        />
                        <!-- INPUT_FILE FIN -->
                        <div class="form-group m-0" style="cursor: pointer">
                          <figure @click="inputImage()" class="m-0">
                            <img width="100%" height="100%" :src="image" />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Valoration -->
                <b-col md="12">
                  <div v-for="rate in rates" :key="rate.id" class="d-flex">
                    <template v-if="rate.id != 4">
                      <div class="d-flex align-items-center">
                        <p class="p-0 m-0">{{ rate.name }}:</p>
                      </div>
                      <div>
                        <b-form-rating
                          variant="warning"
                          size="sm"
                          :readonly="id != null"
                          no-border
                          v-model="rate.value"
                          class="p-0 m-0"
                        >
                        </b-form-rating>
                      </div>
                    </template>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="8" xl="8">
              <b-row class="py-1">
                <!-- Bill Number -->
                <b-col md="6" lg="12" xl="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <b-form-group label="Bill Number" label-for="bill_number">
                      <b-form-input
                        id="bill_number"
                        v-mask="'###################'"
                        v-model="form.bill_number"
                        :disabled="view"
                        placeholder="Bill number"
                        :state="errors[0] ? false : null"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- Date -->
                <b-col md="6" lg="12" xl="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <b-form-group label="Date" label-for="date">
                      <kendo-datepicker
                        id="date"
                        v-model="form.date"
                        v-mask="'##/##/####'"
                        :first-day-of-week="1"
                        placeholder="MM/DD/YYYY"
                        :disabled="view"
                        :format="'MM/dd/yyyy'"
                        :state="errors[0] ? false : null"
                        :class="errors[0] ? 'border-danger' : ''"
                        :min="minDate"
                        :max="maxDate"
                        class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- Purchase Type -->
                <b-col md="6" lg="12" xl="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <b-form-group
                      label="Purchase Type"
                      label-for="purchase_type"
                      :state="errors[0] ? false : null"
                    >
                      <v-select
                        id="purchase_type"
                        :clearable="false"
                        v-model="form.purchase_type"
                        :disabled="view"
                        :options="purchaseTypes"
                        :reduce="(val) => val.id"
                        label="name"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- Country -->
                <b-col md="6" lg="12" xl="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <b-form-group
                      label="Country"
                      label-for="country_id"
                      :state="errors[0] ? false : null"
                    >
                      <v-select
                        id="purchase_type"
                        v-model="form.country_id"
                        :clearable="false"
                        :options="countries"
                        :disabled="view"
                        :reduce="(val) => val.id"
                        @input="changeCountry"
                        label="name"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- Currency -->
                <b-col md="6" lg="6" xl="3">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <b-form-group
                      label="Currency"
                      label-for="currency"
                      :state="errors[0] ? false : null"
                    >
                      <v-select
                        id="currency"
                        :clearable="false"
                        v-model="form.currency"
                        :options="currencies"
                        :reduce="(val) => val.id"
                        :disabled="view || form.country_id != 173"
                        @input="changeCurrency"
                        label="name"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- Currency amount -->
                <b-col md="6" lg="6" xl="3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="validate-amount"
                  >
                    <b-form-group
                      label="Exchange Rate"
                      label-for="currency_amount"
                      :state="errors[0] ? false : null"
                    >
                      <money
                        v-bind="vMoney2"
                        v-model="form.currency_amount"
                        :state="false"
                        :disabled="view"
                        class="form-control"
                        :class="errors[0] && save ? 'border-danger' : ''"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- Vendor -->
                <b-col md="6" lg="12" xl="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <feather-icon
                      v-if="!view"
                      icon="PlusCircleIcon"
                      size="16"
                      v-b-tooltip.hover.v-primary
                      class="cursor-pointer text-primary"
                      style="float: right"
                      title="ADD VENDOR"
                      @click="openModalAddProvider()"
                    />
                    <b-form-group
                      label="Vendor"
                      label-for="vendor"
                      :state="errors[0] ? false : null"
                    >
                      <v-select
                        id="vendor"
                        :clearable="false"
                        v-model="form.vendor"
                        :options="vendors"
                        :disabled="view"
                        :reduce="(val) => val.id"
                        label="name"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- Description -->
                <b-col md="6" lg="12" xl="12">
                  <b-form-group label="Observation" label-for="description">
                    <b-form-textarea
                      id="description"
                      v-model="form.description"
                      placeholder="Enter something..."
                      rows="4"
                      :disabled="view"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div class="border-top-primary border-3">
            <div class="custom-scroll" v-if="this.form.purchase_type == 1">
              <div>
                <div v-if="form.items.length > 0" class="d-flex pt-1">
                  <span class="custom-item">ITEM</span>
                  <div class="custom-packing">
                    <span>BRAND</span>
                    <feather-icon
                      v-if="!view"
                      class="pointer text-primary"
                      style="float: right"
                      icon="PlusCircleIcon"
                      size="16"
                      @click="openModalAddBrand()"
                    />
                  </div>
                  <div class="custom-packing">
                    <span>PACKING</span>
                    <feather-icon
                      v-if="!view"
                      class="pointer text-primary"
                      style="float: right"
                      icon="PlusCircleIcon"
                      size="16"
                      @click="openModalAddPacking()"
                    />
                  </div>
                  <span class="custom-quantity">QUANTITY</span>
                  <span class="custom-price">PRICE</span>
                </div>
              </div>
              <template v-for="(item, key) in form.items">
                <div :key="key" style="padding-top: 7px">
                  <div class="d-flex">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                      <b-form-group
                        :state="errors[0] ? false : null"
                        class="custom-item"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            :id="'item_id' + key"
                            v-model="item.item"
                            :options="filteredOptions"
                            :disabled="view"
                            append-to-body
                            placeholder="Search..."
                            :calculate-position="positionDropdown"
                            :style="`${
                              item.is_detailed ? 'width:95%' : 'width:100%'
                            }`"
                            label="name"
                            @input="selectHandler(key)"
                            @search="searchItems"
                          />
                          <tabler-icon
                            icon="ChevronDownIcon"
                            class="text-success"
                            style="width: 5%; margin-left: 5px"
                            size="22"
                            v-b-toggle="'row-' + key"
                            v-if="item.is_detailed"
                          />
                        </div>
                      </b-form-group>
                    </ValidationProvider>

                    <!-- Brand -->
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        :rules="item.is_detailed ? 'required' : ''"
                      >
                        <b-form-group :state="errors[0] ? false : null">
                          <v-select
                            :id="'brand' + key"
                            v-model="item.brand"
                            :options="brands"
                            :reduce="(val) => val.id"
                            :disabled="
                              item.item_id == null || !item.is_detailed || view
                            "
                            append-to-body
                            :calculate-position="positionDropdown"
                            label="name"
                            class="custom-packing"
                            @input="changeBrand(key)"
                          />
                        </b-form-group>
                      </ValidationProvider>
                    </div>

                    <!-- Packing -->
                    <div>
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <b-form-group :state="errors[0] ? false : null">
                          <v-select
                            :id="'packing' + key"
                            v-model="item.packing_type_id"
                            :options="packingTypes"
                            :reduce="(val) => val.id"
                            :disabled="item.item_id == null || view"
                            append-to-body
                            :calculate-position="positionDropdown"
                            label="name"
                            class="custom-packing"
                          />
                        </b-form-group>
                      </ValidationProvider>
                    </div>

                    <!-- Quantity -->
                    <div class="custom-quantity">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="validate-percentage-min"
                      >
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-button
                              variant="outline-success"
                              style="width: 30px; height: 35px"
                              class="p-0"
                              :disabled="
                                item.item_id == null ||
                                item.quantity < 1 ||
                                view
                              "
                              @click="minusQuantity(key)"
                            >
                              <tabler-icon icon="MinusIcon" size="10" />
                            </b-button>
                          </b-input-group-prepend>

                          <b-form-input
                            :id="'quantity' + key"
                            type="number"
                            v-model="item.quantity"
                            style="width: 50px"
                            class="text-center no-spinners"
                            :disabled="
                              item.item_id == null || item.is_detailed || view
                            "
                            :state="errors[0] ? false : null"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-success"
                              style="width: 30px; height: 35px"
                              class="p-0"
                              :disabled="item.item_id == null || view"
                              @click="plusQuantity(key)"
                            >
                              <tabler-icon icon="PlusIcon" size="10" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </ValidationProvider>
                    </div>

                    <!-- Price -->
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="validate-amount"
                      >
                        <money
                          v-bind="vMoney"
                          v-model="item.price"
                          :state="false"
                          :disabled="
                            item.item_id == null ||
                            (item.item_id != null && item.is_detailed) ||
                            view
                          "
                          class="form-control custom-price"
                          :class="errors[0] && save ? 'border-danger' : ''"
                          @input="calculateTotal"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex">
                      <b-button
                        v-if="!view"
                        variant="outline-danger"
                        class="text-center p-0"
                        v-b-tooltip.hover.left="'Delete Item'"
                        style="width: 35px; height: 35px; margin-left: 5px"
                        @click="removeItem(key)"
                      >
                        <tabler-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                  </div>
                  <div v-if="item.is_detailed">
                    <b-collapse :id="'row-' + key">
                      <div class="d-flex p-1">
                        <div style="width: 40px; min-width: 40px" class="">
                          <span style="width: 40px; min-width: 40px">###</span>
                        </div>
                        <!-- Header for items details -->
                        <template v-for="(field, key2) in item.fields">
                          <div :key="key2" class="custom-specified-column">
                            <span
                              class="text-uppercase custom-specified-column"
                            >
                              {{ field.name }}
                            </span>
                          </div>
                        </template>
                      </div>

                      <!-- Content for Items details -->
                      <template v-for="(det, key3) in item.details">
                        <div
                          :key="key3"
                          style="margin-bottom: 7px"
                          class="pl-1 pr-1 d-flex align-items-center"
                        >
                          <div style="width: 40px; min-width: 40px">
                            <span style="width: 40px; min-width: 40px"
                              >#{{
                                (key3 + 1).toString().padStart(2, "0")
                              }}</span
                            >
                          </div>

                          <template v-for="(itemDet, key4) in det">
                            <div
                              :key="'det-' + key3 + '-' + key4"
                              v-if="itemDet.label_id == 'price'"
                            >
                              <ValidationProvider
                                v-slot="{ errors }"
                                rules="validate-amount"
                              >
                                <money
                                  v-bind="vMoney"
                                  v-model="itemDet.value"
                                  :state="false"
                                  class="form-control custom-specified-column"
                                  :disabled="view"
                                  :class="
                                    errors[0] && save ? 'border-danger' : ''
                                  "
                                  @input="calculateTotal"
                                />
                              </ValidationProvider>
                            </div>
                            <div
                              :key="'det-' + key3 + '-' + key4"
                              v-else-if="itemDet.label_id == 'life_time'"
                            >
                              <ValidationProvider
                                v-slot="{ errors }"
                                rules="validate-percentage-min"
                              >
                                <b-form-input
                                  :id="'val-' + key3 + '-' + key4"
                                  type="number"
                                  v-model="itemDet.value"
                                  :disabled="view"
                                  class="custom-specified-column"
                                  :state="errors[0] ? false : null"
                                />
                              </ValidationProvider>
                            </div>
                            <div
                              :key="'det-' + key3 + '-' + key4"
                              v-else-if="itemDet.label_id == 'salvage_value'"
                            >
                              <ValidationProvider
                                v-slot="{ errors }"
                                rules="validate-amount"
                              >
                                <money
                                  v-bind="vMoney"
                                  v-model="itemDet.value"
                                  :state="false"
                                  class="form-control custom-specified-column"
                                  :disabled="view"
                                  :class="
                                    errors[0] && save ? 'border-danger' : ''
                                  "
                                />
                              </ValidationProvider>
                            </div>
                            <div :key="'det-' + key3 + '-' + key4" v-else>
                              <b-form-input
                                :id="'val-' + key3 + '-' + key4"
                                class="custom-specified-column"
                                :disabled="view"
                                v-model="itemDet.value"
                              />
                            </div>
                          </template>
                          <div :key="key3" class="d-flex" v-if="!view">
                            <tabler-icon
                              icon="CopyIcon"
                              size="19"
                              class="text-primary cursor-pointer"
                              style="
                                margin-left: 2px;
                                margin-right: 2px;
                                margin-top: 2px;
                              "
                              v-b-tooltip.hover.left="'Copy row'"
                              @click="copyDataRow(key, key3)"
                            />
                            <tabler-icon
                              v-if="!view"
                              class="text-danger ml-1 cursor-pointer"
                              size="19"
                              icon="TrashIcon"
                              @click="removeItemDetail(key, key3, det)"
                            />
                            <!-- </b-button> -->
                          </div>
                        </div>
                      </template>
                    </b-collapse>
                  </div>
                </div>
              </template>
            </div>
            <div
              class="mt-1 d-flex"
              :class="
                form.purchase_type == 1
                  ? 'justify-content-between'
                  : 'justify-content-end'
              "
            >
              <b-button
                variant="outline-success"
                @click="addItems"
                v-if="form.purchase_type == 1 && !view"
                >+ ITEM</b-button
              >
              <div>
                <ValidationProvider v-slot="{ errors }" rules="validate-amount">
                  <div class="d-flex align-items-center">
                    <span>Total: </span>
                    <money
                      v-bind="vMoney"
                      v-model="form.total"
                      :disabled="form.purchase_type == 1 || view"
                      class="form-control custom-price"
                      :class="errors[0] && save ? 'border-danger' : ''"
                    />
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <template #modal-footer>
        <div>
          <b-button variant="outline-danger" @click="$emit('hidden')">{{
            view ? "CLOSE" : "CANCEL"
          }}</b-button>
        </div>
        <div>
          <b-button
            v-if="id == null && !view"
            variant="outline-primary"
            @click="insertPurchaseOrder"
            ><feather-icon icon="SaveIcon" class="mr-1" />
            {{ "Save" }}</b-button
          >
          <b-button
            v-if="id != null && !view"
            variant="outline-warning"
            @click="editPurchaseOrder"
            ><feather-icon icon="SaveIcon" class="mr-1" /> Edit</b-button
          >
        </div>
      </template>
    </b-modal>

    <b-modal
      modal
      v-model="ModalAddBrand"
      title="CREATE BRAND"
      @ok.prevent="registerBrand"
      ok-title="SAVE"
    >
      <div>
        <ValidationObserver ref="formBrand">
          <b-row>
            <b-col sm="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group label="BRAND:" label-for="txtBrand">
                  <b-form-input
                    v-model="newBrand"
                    id="txtBrand"
                    placeholder="..."
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    Brand {{ errors[0] }}
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
    <vendor-modal
      v-if="modalAddProvider"
      :type-modal="'ADD'"
      :opened-from="'merchandising'"
      @closeModal="closeModalAddProvider"
      @refreshTable="saveVendor"
    />
    <modal-packings
      v-if="modalAddPacking"
      @hidden="closeModalAddPacking"
      @closeModal="closeModalAddPacking"
      @onCreatePackings="refreshTablePackings"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { VueAutosuggest } from "vue-autosuggest";
import { mapGetters } from "vuex";
import VMoney from "v-money";
import BillsService from "@/views/logistic/views/bills/service/bills.service.js";
import SettingsService from "@/views/logistic/views/settings/services/settings.service.js";
import VendorModal from "@/views/logistic/views/vendors/views/modals/VendorModal.vue";
import ModalPackings from "@/views/logistic/views/settings/components/ModalPackings.vue";

export default {
  components: {
    VueAutosuggest,
    VMoney,
    VendorModal,
    ModalPackings,
  },
  directives: {
    money: VMoney,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    view: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "NEW",
      requiredImg: false,
      form: {
        date: null,
        purchase_type: null,
        country_id: null,
        currency: null,
        currency_amount: 0,
        vendor: null,
        description: null,
        bill_number: null,
        total: 0,
        user_id: null,
        images: null,
        items: [],
        rates: [],
      },
      purchaseTypes: [],
      newBrand: "",
      vendors: [],
      arrayBrand: [],
      ModalAddBrand: false,
      viewImage: "/images/inventory.jpg",
      filteredOptions: [],
      countries: [],
      packingTypes: [],
      brands: [],
      currencies: [
        { id: 1, name: "$" },
        { id: 2, name: "S/" },
      ],
      selectedKey: null,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      vMoney2: {
        decimal: ",",
        thousand: ".",
        prefix: "S/ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      save: false,
      rates: [],
      fieldDefault: [
        {
          id: "price",
          name: "Price",
        },
        {
          id: "life_time",
          name: "Life Time",
        },
        {
          id: "salvage_value",
          name: "Salvage Value",
        },
      ],
      copyData: null,
      modalAddProvider: false,
      modalAddPacking: false,
    };
  },
  computed: {
    image() {
      if (this.viewImage == "/images/inventory.jpg") {
        return this.baseUrl + this.viewImage;
      } else {
        return this.viewImage;
      }
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    minDate() {
      const date = new Date();
      return new Date("1990", date.getMonth(), 1);
    },
    maxDate() {
      const date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
  },
  watch: {
    async "form.vendor"() {
      await this.getRates();
    },
  },
  async created() {
    try {
      this.addPreloader();
      await Promise.all([
        this.getDataPurchaseOrder(),
        this.getCountries(),
        this.getVendors(),
        this.getPackingTypes(),
        this.getBrands(),
        this.getExchangeRate(),
        this.getPurchaseTypes(),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      this.removePreloader();
    }
  },
  mounted() {
    this.toggleModal("modal-purchase-order");
  },
  methods: {
    closeModalAddBrand() {
      this.ModalAddBrand = false;
    },

    openModalAddBrand() {
      this.ModalAddBrand = true;
    },

    async registerBrand() {
      try {
        const resultValidate = await this.$refs.formBrand.validate();
        const validateBrand = this.arrayBrand.includes(
          this.newBrand.toLowerCase()
        );

        if (resultValidate) {
          if (!validateBrand) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              this.addPreloader();
              const data = await BillsService.saveBrand({
                name: this.newBrand,
                created_by: this.currentUser.user_id,
              });
              if (data.status === 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Brand has been created successfully"
                );
                this.newBrand = "";
                this.removePreloader();
                this.closeModalAddBrand();
                await this.getBrands();
              }
            }
          } else {
            this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              "The entered Brand already exists"
            );
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getDataPurchaseOrder() {
      if (!this.id) {
        return;
      }
      try {
        const { data } = await BillsService.getDataPurchaseOrderById({
          id: this.id,
        });
        this.viewImage = data[0].bill_photo || "/images/inventory.jpg";
        this.form.bill_number = data[0].bill_number;
        // this.form.country_id = data[0].bill_number;
        this.form.date = data[0].date;
        this.form.purchase_type = data[0].purchase_type_id;
        this.form.country_id = data[0].country_id;
        this.form.currency = data[0].currency;
        this.form.currency_amount =
          data[0].currency_amount != null ? data[0].currency_amount : 0;
        this.form.vendor = data[0].vendor_id;
        this.form.description = data[0].description;
        this.form.total = data[0].total;

        let order = data[0];
        order.rates = order.rates == null ? [] : JSON.parse(order.rates);
        this.form.rates = order.rates;
        order.items = order.items != null ? JSON.parse(order.items) : [];
        // order.items = JSON.parse(order.items);
        order.items.map((item) => {
          item.fields = item.fields == null ? [] : item.fields;
          item.fields = [...this.fieldDefault, ...item.fields];
          item.details = item.details == null ? [] : item.details;
          item.is_detailed = item.is_detailed == 1;
          if (item.is_detailed) {
            const newDetail = [];
            item.brand = item.details[0][0].brand;
            item.details.map((det) => {
              const itemFields = [];
              item.fields.map((field) => {
                if (field.id == "price") {
                  itemFields.push({
                    label_id: "price",
                    label_name: "Price",
                    value: det[0].price,
                  });
                } else if (field.id == "life_time") {
                  itemFields.push({
                    label_id: "life_time",
                    label_name: "Life Time",
                    value: det[0].life_time,
                  });
                } else if (field.id == "salvage_value") {
                  itemFields.push({
                    label_id: "salvage_value",
                    label_name: "Salvage Value",
                    value: det[0].salvage_value,
                  });
                } else {
                  det.map((det2) => {
                    if (det2.label_id != null && det2.label_id == field.id) {
                      itemFields.push({
                        label_id: field.id,
                        label_name: field.name,
                        value: det2.value,
                      });
                    }
                  });
                }
              });
              newDetail.push(itemFields);
            });
            item.details = newDetail;
          }
        });
        this.form.items = order.items;
        this.changeCurrency();
      } catch (error) {
        console.log(error);
        this.showErrorSwal();
      }
    },

    positionDropdown(dropdownList, component, { width, top, left }) {
      dropdownList.style.zIndex = 9999;
      dropdownList.style.maxHeight = "20rem";
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    async insertPurchaseOrder() {
      try {
        this.save = true;
        if (!this.form.images && this.form.purchase_type == 1) {
          this.requiredImg = this.form.purchase_type == 1;
          await this.$refs.formPurchaseOrder.validate();
          return;
        }
        const validate = await this.$refs.formPurchaseOrder.validate();
        if (validate) {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            this.addPreloader();
            // this.form.user_id = this.currentUser.user_id;
            const formData = new FormData();
            formData.append("bill_number", this.form.bill_number);
            formData.append("country_id", this.form.country_id);
            formData.append("currency", this.form.currency);
            formData.append("currency_amount", this.form.currency_amount);
            formData.append("date", this.form.date);
            formData.append("description", this.form.description);
            formData.append(
              "items",
              JSON.stringify(this.form.items).toString()
            );
            formData.append("purchase_type", this.form.purchase_type);
            formData.append("total", this.form.total);
            formData.append("user_id", this.currentUser.user_id);
            formData.append("vendor", this.form.vendor);
            formData.append("bill_photo", this.form.images);
            formData.append("rates", JSON.stringify(this.rates).toString());
            const { data } = await BillsService.insertPurchaseOrder(formData);
            if (data.type == "success") {
              this.showSuccessSwal(data.message);
              this.$emit("refresh");
            } else if (data.type == "warning") {
              this.showWarningSwal("Warning", data.message);
            }
          }
        }
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async editPurchaseOrder() {
      try {
        this.save = true;
        if (!this.form.images && this.viewImage == "/images/inventory.jpg") {
          this.requiredImg = true;
          await this.$refs.formPurchaseOrder.validate();
          return;
        }
        const validate = await this.$refs.formPurchaseOrder.validate();
        if (validate) {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            this.addPreloader();
            // this.form.user_id = this.currentUser.user_id;
            const formData = new FormData();
            formData.append("id", this.id);
            formData.append("bill_number", this.form.bill_number);
            formData.append("country_id", this.form.country_id);
            formData.append("currency", this.form.currency);
            formData.append("currency_amount", this.form.currency_amount);
            formData.append("date", this.form.date);
            formData.append("description", this.form.description);
            formData.append(
              "items",
              JSON.stringify(this.form.items).toString()
            );
            formData.append("purchase_type", this.form.purchase_type);
            formData.append("total", this.form.total);
            formData.append("user_id", this.currentUser.user_id);
            formData.append("vendor", this.form.vendor);
            formData.append("bill_photo", this.form.images);
            formData.append("rates", JSON.stringify(this.rates).toString());
            const { data } = await BillsService.editPurchaseOrder(formData);
            if (data.type == "success") {
              this.showSuccessSwal(data.message);
              this.$emit("refresh");
            }
          }
        }
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async getPredefSpecifBySubcategory(sub_category_id) {
      try {
        const { data } =
          await BillsService.getPredefinedSpecificationsBySubcategory({
            sub_category_id,
          });
        return data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async saveVendor() {
      await this.changeCountry();
    },
    async getVendors() {
      try {
        const { data } = await BillsService.getVendors();
        this.vendors = data;
      } catch (error) {
        this.showErrorSwal();
      }
    },

    async getExchangeRate() {
      if (this.id != null) return;
      try {
        const { data } = await BillsService.getExchangeRate();
        this.form.currency_amount = data.venta;
      } catch (error) {
        console.log(error);
        this.showErrorSwal();
      }
    },

    async getCountries() {
      try {
        const { data } = await BillsService.getCountries();
        this.countries = data;
      } catch (error) {
        this.showErrorSwal();
      }
    },

    async getPackingTypes() {
      try {
        const { data } = await SettingsService.getPackings();
        if (this.view) {
          this.packingTypes = data;
          return;
        }
        this.packingTypes = data.filter(
          (packing) => packing.deleted_at == null
        );
      } catch (error) {
        this.showErrorSwal();
      }
    },

    async getBrands() {
      try {
        const { data } = await BillsService.getBrands();
        this.brands = data;

        this.arrayBrand = [];
        data.forEach((item) => {
          this.arrayBrand.push(item.name.toLowerCase());
        });
      } catch (error) {
        this.showErrorSwal();
      }
    },

    async getPurchaseTypes() {
      try {
        const { data } = await BillsService.getPurchaseTypes();
        this.purchaseTypes = data;
      } catch (error) {
        this.showErrorSwal();
      }
    },

    async getRates() {
      const params = {
        vendorId: this.form.vendor,
      };
      try {
        const { data } = await BillsService.getRates(params);
        this.rates = data.map((rate) => {
          return {
            ...rate,
            value: 0,
          };
        });
        if (this.id != null) {
          this.rates.map((rate) => {
            const editRate = this.form.rates.find(
              (rate2) => rate2.id == rate.id
            );
            if (editRate) {
              rate.value = editRate.score;
            }
          });
        }
      } catch (error) {
        this.showErrorSwal();
      }
    },

    getImage(e) {
      this.requiredImg = false;
      if (!e.target.files.length) {
        this.requiredImg = true;
        this.form.images = null;
        this.viewImage = "/images/inventory.jpg";
        return;
      }
      let file = e.target.files[0];
      this.form.images = file;
      this.uploadImage(file);
    },

    uploadImage: function (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.viewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    inputImage() {
      document.getElementById("file_input_1").click();
    },

    addItems() {
      this.form.items.push({
        item: null,
        item_id: null,
        item_name: "",
        purchase_order_id: null,
        packing_type_id: null,
        quantity: 0,
        price: 0,
        is_detailed: false,
        fields: [],
        details: [],
      });
    },

    removeItem(index) {
      const items = [];
      this.form.items.map((row, index2) => {
        if (index2 != index) {
          items.push(row);
        }
      });
      this.form.items = items;
    },

    changeQuantity(key) {
      const newQuatity = this.form.items[key].quantity;
      if (this.form.items[key].is_detailed) {
        const itemDetails = [];
        for (let index = 0; index < newQuatity; index++) {
          const itemFields = [];
          this.form.items[key].fields.map((field) => {
            itemFields.push({
              label_id: field.id,
              label_name: field.name,
              value:
                field.id == "price" ||
                field.id == "life_time" ||
                field.id == "salvage_value"
                  ? 0
                  : "",
            });
          });
          itemDetails.push(itemFields);
        }
        this.form.items[key].details = itemDetails;
      }
    },

    searchItems: _.debounce(async function (search) {
      if (search.length < 1 || search == undefined) return;
      const { data } = await BillsService.searchItemsLogistic({ text: search });
      this.filteredOptions = data;
    }),

    async selectHandler(key) {
      const item = this.form.items[key].item;
      if (item != null) {
        const { id, sub_category_id, is_detailed } = this.form.items[key].item;
        this.form.items[key].item_id = id;
        let fields = [];

        if (is_detailed == 1) {
          fields = await this.getPredefSpecifBySubcategory(sub_category_id);
        }

        this.form.items[key].fields = [...this.fieldDefault, ...fields];
        this.form.items[key].is_detailed = is_detailed == 1 ? true : false;
        this.form.items[key].item_id = id;
        this.form.items[key].brand = null;
        this.form.items[key].packing_type_id = null;
        this.form.items[key].quantity = 0;
        this.form.items[key].price = 0;
        this.form.items[key].details = [];
        return;
      }

      this.form.items[key].item_id = null;
      this.form.items[key].item_name = "";
      this.form.items[key].brand = null;
      this.form.items[key].packing_type_id = null;
      this.form.items[key].quantity = 0;
      this.form.items[key].price = 0;
      this.form.items[key].is_detailed = false;
      this.form.items[key].details = [];
      this.form.items[key].fields = [];
    },

    clickHandler(e) {
      this.selectedKey = e;
    },

    getSuggestionValue(suggestion) {
      // const item_id = suggestion.item.id;
      // const item = this.form.items.find(
      //   (row, key) => row.item_id == item_id && key != this.selectedKey
      // );
      // if (item) {
      this.form.items[this.selectedKey].item_id = null;
      this.form.items[this.selectedKey].item_name = "";
      this.form.items[this.selectedKey].brand = null;
      this.form.items[this.selectedKey].packing_type_id = null;
      this.form.items[this.selectedKey].quantity = 0;
      this.form.items[this.selectedKey].price = 0;
      this.form.items[this.selectedKey].is_detailed = false;
      this.form.items[this.selectedKey].details = [];
      this.form.items[this.selectedKey].fields = [];
      // this.showWarningSwal("Warning", "Item is already registered.");
      // return "";
      // }
      return suggestion.item.name;
    },

    changeCurrency() {
      if (this.form.currency == 1) {
        this.vMoney.prefix = "$ ";
      } else {
        this.vMoney.prefix = "S/ ";
      }
    },

    async changeCountry() {
      if (this.form.country_id == 75) {
        this.form.currency = 1;
        this.changeCurrency();
      }
      this.vendors = [];
      this.form.vendor = null;
      try {
        const { data } = await BillsService.getVendors({
          country_id: this.form.country_id,
        });
        this.vendors = data;
      } catch (error) {
        console.log(error);
      }
    },

    changeBrand(key) {
      const { item_id, brand } = this.form.items[key];
      const item = this.form.items.find(
        (row, index) =>
          row.item_id == item_id && row.brand == brand && index != key
      );
      if (item) {
        this.form.items[key].brand = null;
        this.showWarningSwal("Warning", "Item is already registered.");
      }
    },

    calculateTotal() {
      let total = 0;
      this.form.items.map((item) => {
        if (item.is_detailed) {
          let subtotal = 0;
          item.details.map((det) => {
            det[0].value = det[0].value == "" ? 0 : det[0].value;
            subtotal += det[0].value;
            total += det[0].value;
          });
          item.price = subtotal;
        } else {
          total += item.price;
        }
      });
      this.form.total = total;
    },

    minusQuantity(key) {
      if (this.form.items[key].is_detailed) {
        this.form.items[key].details.pop();
      }
      this.form.items[key].quantity--;
    },

    plusQuantity(key) {
      if (this.form.items[key].is_detailed) {
        const itemFields = [];
        this.form.items[key].fields.map((field) => {
          itemFields.push({
            label_id: field.id,
            label_name: field.name,
            value:
              field.id == "price" ||
              field.id == "life_time" ||
              field.id == "salvage_value"
                ? 0
                : "",
          });
        });
        this.form.items[key].details.push(itemFields);
      }
      this.form.items[key].quantity++;
    },

    removeItemDetail(key, key3) {
      const newDetail = this.form.items[key].details.filter(
        (det, key4) => key4 != key3
      );
      this.form.items[key].details = newDetail;
      this.form.items[key].quantity--;
    },

    copyDataRow(key, key3) {
      const row = this.form.items[key].details[key3];
      this.form.items[key].details.map((det2) => {
        det2.map((det3) => {
          row.map((row2) => {
            if (row2.label_id == det3.label_id) det3.value = row2.value;
          });
        });
      });

      this.showToast(
        "success",
        "top-right",
        "Copy Row",
        "CheckIcon",
        "Row was copied."
      );
    },

    pasteDataRow(key, key3, det) {
      if (key == this.copyData.itemKey) {
        this.form.items[key].details[key3].map((col) => {
          this.copyData.row.map((col2) => {
            if (col.label_id == col2.label_id) {
              col.value = col2.value;
            }
          });
        });
      }
      this.copyData = null;
    },
    openModalAddProvider() {
      this.modalAddProvider = true;
    },
    closeModalAddProvider() {
      this.modalAddProvider = false;
    },
    openModalAddPacking() {
      this.modalAddPacking = true;
    },
    closeModalAddPacking() {
      this.modalAddPacking = false;
    },
    async refreshTablePackings() {
      await this.getPackingTypes();
    },
  },
};
</script>

<style>
.custom-scroll {
  overflow-y: scroll;
  max-height: 300px;
}

.custom-item {
  width: 350px;
  min-width: 350px;
  margin-left: 5px;
  margin-right: 5px;
}

.custom-packing {
  width: 250px;
  min-width: 250px;
  margin-left: 5px;
  margin-right: 5px;
}

.custom-quantity {
  width: 140px;
  min-width: 140px;
  margin-left: 5px;
  margin-right: 5px;
}

.custom-price {
  width: 110px;
  min-width: 110px;
  margin-left: 5px;
  margin-right: 5px;
}

.custom-specified-column {
  width: 150px;
  min-width: 150px;
  margin-left: 5px;
  margin-right: 5px;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>



