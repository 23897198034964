<template>
  <div>
    <PurchaseOrderTable ref="purchaseOrderTable" />
  </div>
</template>

<script>
import PurchaseOrderTable from "@/views/logistic/views/bills/components/purchase-order/PurchaseOrderTable.vue";
export default {
  components: {
    PurchaseOrderTable,
  },
  methods:{
    newPurchaseOrder(){
        this.$refs.purchaseOrderTable.newPuchaseOrder();
    }
  }
};
</script>

<style>
</style>