export default [
    {
        // [0]
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
    },
    {
        // [1]
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
    },
    {
        // [2]
        type: "select",
        margin: true,
        showLabel: true,
        label: "Purchase Type",
        model: null,
        options: [],
        reduce: "id",
        selectText: "name",
        cols: 6,
    },
    {
        // [3]
        type: "select",
        margin: true,
        showLabel: true,
        label: "Country",
        model: null,
        options: [],
        reduce: "id",
        selectText: "name",
        cols: 6,
    },
    {
        // [4]
        type: "select",
        margin: true,
        showLabel: true,
        label: "Vendor",
        model: null,
        options: [],
        reduce: "id",
        selectText: "name",
        cols: 12,
    },
    {
        // [5]
        type: "select",
        margin: true,
        showLabel: true,
        label: "Status",
        model: null,
        options: [
            { id: 1, name: "Pending" },
            { id: 2, name: "Approved" },
        ],
        reduce: "id",
        selectText: "name",
        cols: 12,
    },
]